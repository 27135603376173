import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ogImage from "../images/logos/Primary Logo-black-1000px.png";

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        siteUrl
        twitterUsername
      }
    }
  }
`

const SEO = ({ title, description, location }) => {
  const { site } = useStaticQuery(getData)
  
  const {
    siteDesc,
    siteTitle,
    siteUrl,
    twitterUsername
  } = site.siteMetadata

  const imageUrl = `${siteUrl}${ogImage}`
  const url = location.href

  const fullTitle = title ? `${title} | ${siteTitle}` : siteTitle;

  return (
    <Helmet htmlAttribute={{ lang: "en" }} title={fullTitle}>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={imageUrl} />
      <link rel="canonical" href={url} />
      {/* Facebook Card */}
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image" content="400" />
      <meta property="og:image:height" content="300" />

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={siteDesc} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  )
}

export default SEO

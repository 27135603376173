import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link } from "gatsby"
import styled from "styled-components"
import paths from "../../constants/paths"
import FormattedDate from "./FormattedDate"
import Seo from "../SEO"

const shortcodes = { Link } // Provide common components here

const ViewAllPosts = styled.div`
  margin: 1rem;
  text-align: center;

  a {
    font-size: 80%;
    color: brand-color-light-blue;
  }

  @media (min-width: 768px) {
    margin: 4rem 0;
  }
`

const PostContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  h1 {
    margin-bottom: 0;
    margin-top: 0.25rem;
  }

  p {
    max-width: 900px;
    margin: 1rem auto;
    padding: 0 1rem;
  }

  @media (min-width: 768px) {
    padding: 0 2.5rem;

    header {
      margin: 2rem 0;
    }
  }
`

const Date = styled.div`
  text-align: center;
`

export default function PageTemplate(props) {
  const { data: { mdx } } = props
  const pageTitle = mdx.frontmatter.seo_description || mdx.frontmatter.title;
  return (
    <>
      <Seo title={pageTitle} location={props.location} description={mdx.frontmatter.seo_description} />
      <PostContainer>
        <header>
          <h1>{mdx.frontmatter.title}</h1>
          <Date>{FormattedDate(mdx.frontmatter.date)}</Date>
        </header>
        <MDXProvider components={shortcodes}>
          <MDXRenderer frontmatter={mdx.frontmatter}>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </PostContainer>
      <ViewAllPosts>
        <Link className="pure-button full-touch-button" to={paths.blog.url}>View All Posts</Link>
      </ViewAllPosts>
    </>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date
        title
        seo_title
        seo_description
      }
    }
  }
`
